<template>
  <template v-if="!reports.SUMMARY_RESAMPLED">
    <div>Data for multichart is outdated</div>
  </template>

  <template v-else>
    <div class="w-full flex flex-wrap gap-4 mb-4">
      <div
        v-for="chart in legendWithData"
        :key="chart.scaleId"
        class="flex items-center"
      >
        <div
          class="h-3 w-3 mr-2"
          :style="{ backgroundColor: chart.color }"
        ></div>
        <div class="text-xs">{{ chart.title }}: {{ chart.currentValue }}</div>
      </div>
    </div>

    <div class="w-full relative mx-auto p-4 flex bg-white shadow rounded-lg">
      <button
        @click.prevent="handleClick"
        class="absolute top-2 right-2 z-10 flex items-center"
      >
        <ArrowsPointingOutIcon class="h-6 w-6" aria-hidden="true" />
      </button>
      <Line
        :data="chartData"
        :options="options"
        :plugins="plugins"
        ref="multiChart"
        id="chart"
        class="w-full min-h-[400px]"
      />
    </div>

    <ChartModal
      v-if="fullscreenMultichart"
      :is-open="fullscreenMultichart"
      xMark
      large
      @handleCancel="handleCancel"
    >
      <template v-slot:title>
        <div class="flex items-center gap-3 mb-3">
          <SelectControl
            :style="{
              flexDirection: 'row',
              marginBottom: '0',
              width: 'auto',
              height: 'fit-content',
            }"
            dataKey="trackingMode"
            :data="trackingMode"
            :options="trackingModesOptions"
            @update="handleTrackingMode"
          />
          <div
            v-for="item of multicharts"
            :key="item.scaleID"
            class="flex items-center ml-4 mb-4"
          >
            <input
              type="checkbox"
              class="w-4 h-4 focus:ring-0 focus:ring-offset-0 checked:text-[#5A5A5F] cursor-pointer"
              :style="{ color: item.color }"
              :id="item.title"
              v-model="item.checked"
            />
            <label
              :for="item.title"
              class="text-[14px] font-poppins text-[#5A5A5F] cursor-pointer ml-2 font-bold border-gray-700"
            >
              <span>{{ $t(item.title) }}</span>
            </label>
          </div>
        </div>
      </template>
      <template v-slot:content>
        <div class="w-full flex flex-wrap gap-4 mb-4">
          <div
            v-for="chart in legendWithData"
            :key="chart.scaleId"
            class="flex items-center"
          >
            <div
              class="h-3 w-3 mr-2"
              :style="{ backgroundColor: chart.color }"
            ></div>
            <div class="text-xs">
              {{ chart.title }}: {{ chart.currentValue }}
            </div>
          </div>
        </div>
        <div class="w-full h-[80%] mx-auto p-4 flex bg-white shadow rounded-lg">
          <Line
            :data="chartData"
            :options="options"
            :plugins="plugins"
            ref="multiChart"
            id="chart"
            class="w-full h-[50%]"
          />
        </div>
        <div class="mt-4 mb-4 mx-8 flex items-center gap-8">
          <div class="w-[30%]">
            <PlayControllerButtonsComponent
              :trackingMarkersLength="trackingMarkersLength"
              :cutValue="cutValue"
            />
            <div class="flex mt-1 gap-2">
              <div
                v-for="(item, index) of movements"
                :key="index"
                class="flex items-center mx-1"
              >
                <input
                  type="checkbox"
                  class="w-4 h-4 focus:ring-0 focus:ring-offset-0 checked:text-[#5A5A5F] cursor-pointer"
                  :id="item.title"
                  v-model="item.checked"
                />
                <span
                  class="w-[10px] h-[10px] mx-1 mb-[3px] rounded-full"
                  :class="getClass(item.backgroundColor)"
                ></span>
                <span class="text-[#5A5A5F] text-[18px]">
                  {{ $t(item.title) }}
                </span>
              </div>
            </div>
          </div>

          <div class="w-[70%]">
            <PlayControllerSliderComponent
              :trackingMarkersLength="trackingMarkersLength"
              :cutValue="cutValue"
            />
          </div>
        </div>
      </template>
    </ChartModal>
  </template>
</template>

<script>
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
} from "chart.js";

import annotationPlugin from "chartjs-plugin-annotation";
import { CrosshairPlugin } from "chartjs-plugin-crosshair";
import { Line, Scatter } from "vue-chartjs";
import zoomPlugin from "chartjs-plugin-zoom";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { ArrowsPointingOutIcon } from "@heroicons/vue/24/outline";
import ChartModal from "@/components/training/tracking/trackingComponents/multiChart/ChartModal.vue";
import PlayControllerSliderComponent from "@/components/training/tracking/PlayControllerSliderComponent.vue";
import PlayControllerButtonsComponent from "@/components/training/tracking/PlayControllerButtonsComponent.vue";

import { getClass, getSecond, getSeconds } from "@/components/training/helpers";
import {
  getSecondsFromMinutesTimestamp,
  getBackgroundColorByGait,
  setLocalZoomData,
  setScalesMinMax,
  generateZeroLine,
  generateDataByTrainingTime,
  generateInterpolatedDataByTrainingTime,
} from "@/components/training/tracking/trackingComponents/multiChart/multiChartUtils";
import {
  movements,
  trackingModes,
  trackingModeKeys,
  defaultGaitData,
} from "@/components/training/constants";
import SelectControl from "@/components/UI/SelectControl.vue";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  annotationPlugin,
  zoomPlugin,
  CrosshairPlugin
);

export default {
  name: "MultipleChartComponent",
  components: {
    SelectControl,
    PlayControllerButtonsComponent,
    PlayControllerSliderComponent,
    ArrowsPointingOutIcon,
    ChartModal,
    Line,
    // eslint-disable-next-line vue/no-unused-components
    Scatter,
  },
  props: {
    mappedData: Array,
    multicharts: Array,
    reports: Object,
    cutValue: Array,
    secondsByGait: Array,
    trackingMarkersLength: Number,
  },
  data() {
    return {
      movements,
      trackingModes,
      defaultGaitData,
      isDataReady: false,
      chartData: {
        datasets: [],
      },
      originalData: [],
      legendWithData: [],
      fullscreenMultichart: false,
      localZoom: {
        x: { min: 0, max: 0, step: 0 },
      },
    };
  },

  computed: {
    ...mapGetters([
      "GPSDatafiles",
      "HRDatafiles",
      "currentTrackIndex",
      "isTrackStarted",
      "isTrackPaused",
      "trackingMode",
      "chartPoints",
    ]),

    plugins() {
      return [
        {
          id: "customTitle",
          afterDraw: (chart) => {
            const {
              ctx,
              chartArea: { top },
              scales,
            } = chart;

            Object.keys(scales).forEach((scaleKey) => {
              const scale = scales[scaleKey];
              if (scale.isHorizontal() || !scale.options.display) return;

              ctx.save();

              // Get the title text and color manually
              const titleText = scale.options.title.text;
              const titleColor = scale.options.title.color || "#666";

              // Do not render the default vertical title
              scale.options.title.display = false;

              ctx.fillStyle = titleColor;
              ctx.font = "12px Arial";

              // Calculate position for the horizontal title
              const xPos = scale.left + scale.width / 2;
              const yPos = top - 10;

              ctx.textAlign = "center";
              ctx.fillText(titleText, xPos, yPos);

              ctx.restore();
            });
          },
        },
        {
          id: "customBackground",
          beforeDraw: (chart) => {
            const {
              ctx,
              chartArea: { top, height },
              scales: { x },
            } = chart;

            ctx.save();

            if (x.ticks.length) {
              // Creating intervals to fill it with colors background
              const intervals = [];

              if (this.cutValue) {
                const firstVisibleTickIndex = x.ticks[0].value;
                const second =
                  Math.floor(x.ticks[0].value + this.cutValue[0] / 5) + 1;
                const croppedData = this.secondsByGait.slice(
                  this.cutValue[0],
                  this.cutValue[1]
                );

                // Find the first second and gait value
                let startSecond = croppedData.find(
                  (i) => i.second === second
                )?.second;

                let currentGait = croppedData.find(
                  (i) => i.second === second
                )?.gait;

                let startIndex = croppedData.findIndex(
                  (i) => i.second === second
                );

                for (let i = startIndex; i < croppedData.length - 1; i++) {
                  const currentSecond = croppedData[i].second;
                  const nextGait = croppedData[i].gait;

                  if (nextGait !== currentGait) {
                    intervals.push({
                      start:
                        firstVisibleTickIndex +
                        startSecond -
                        Math.floor(x.ticks[0].value + this.cutValue[0] / 5) +
                        1,
                      end:
                        firstVisibleTickIndex +
                        currentSecond -
                        Math.floor(x.ticks[0].value + this.cutValue[0] / 5) +
                        1,
                      gait: currentGait,
                      color: this.getBackgroundColorByGait(currentGait),
                    });

                    // Update the start of the next interval
                    startSecond = currentSecond;
                    currentGait = nextGait;
                  }
                }

                // Add the last interval
                intervals.push({
                  start:
                    firstVisibleTickIndex +
                    startSecond -
                    Math.floor(x.ticks[0].value + this.cutValue[0] / 5) +
                    1,
                  end:
                    firstVisibleTickIndex +
                    croppedData[croppedData.length - 1].second,
                  gait: currentGait,
                  color: this.getBackgroundColorByGait(currentGait),
                });
              } else {
                const firstVisibleTickIndex = x.ticks[0].value;
                // Find the first second and gait value
                let startSecond = this.secondsByGait.find(
                  (i) => i.second === firstVisibleTickIndex
                )?.second;

                let currentGait = this.secondsByGait.find(
                  (i) => i.second === firstVisibleTickIndex
                )?.gait;

                let startIndex = this.secondsByGait.findIndex(
                  (i) => i.second === firstVisibleTickIndex
                );

                for (
                  let i = startIndex;
                  i < this.secondsByGait.length - 1;
                  i++
                ) {
                  const currentSecond = this.secondsByGait[i].second;
                  const nextGait = this.secondsByGait[i].gait;

                  if (nextGait !== currentGait) {
                    intervals.push({
                      start: startSecond,
                      end: currentSecond,
                      gait: currentGait,
                      color: this.getBackgroundColorByGait(currentGait),
                    });

                    // Update the start of the next interval
                    startSecond = currentSecond;
                    currentGait = nextGait;
                  }
                }

                // Add the last interval
                intervals.push({
                  start: startSecond,
                  end: this.secondsByGait[this.secondsByGait.length - 1].second,
                  gait: currentGait,
                  color: this.getBackgroundColorByGait(currentGait),
                });
              }

              // Draw background for each interval
              for (let i = 0; i < intervals.length; i++) {
                const interval = intervals[i];
                const startX = x.getPixelForValue(interval.start);
                const endX = x.getPixelForValue(interval.end);
                const intervalWidth = endX - startX;

                ctx.fillStyle = interval.color;
                ctx.fillRect(startX, top, intervalWidth, height);
              }
            }

            ctx.restore();
          },
        },
      ];
    },

    options() {
      return {
        animation: false,
        onClick: (event, item, chart) => {
          const xScale = chart.scales.x;
          const xValue = xScale.getValueForPixel(event.x);
          const label = chart.data.labels[Math.round(xValue)];
          const trackIndex = this.cutValue
            ? this.getSecondsFromMinutesTimestamp(label) * 5 - this.cutValue[0]
            : this.getSecondsFromMinutesTimestamp(label) * 5;
          this.SET_CURRENT_TRACK_INDEX(trackIndex);
        },
        plugins: {
          crosshair: {
            sync: {
              enabled: false,
            },
            zoom: {
              enabled: false,
            },
            line: {
              color: "gray",
              dashPattern: [5, 5],
            },
          },
          zoom: {
            pan: {
              enabled: true,
              mode: "xy",
              overScaleMode: "y",
              onPanComplete: ({ chart }) => {
                this.localZoom = {
                  ...this.setLocalZoomData(chart),
                };
              },
            },
            zoom: {
              wheel: { enabled: true, modifierKey: "ctrl" },
              pinch: { enabled: true },
              mode: "xy",
              overScaleMode: "y",
              onZoomComplete: ({ chart }) => {
                this.localZoom = {
                  ...this.setLocalZoomData(chart),
                };
              },
            },
          },
          datalabels: false,
          decimation: true,
          colors: { forceOverride: true },
          annotation: {
            annotations: {
              annotation: {
                type: "line",
                borderColor: () => {
                  let currentGait = "";

                  if (this.cutValue) {
                    const currentSecond =
                      +Math.floor(this.currentTrackIndex / 5) +
                      Math.floor(this.cutValue[0] / 5);

                    currentGait = this.secondsByGait.find(
                      (i) => i.second === currentSecond
                    );
                  } else {
                    currentGait = this.secondsByGait.find(
                      (i) =>
                        i.second ===
                          this.options.plugins.annotation.annotations.annotation
                            .value || 0
                    );
                  }

                  switch (currentGait?.gait) {
                    case "walk":
                      return "#1AB0B0";
                    case "trot":
                      return "#FFCD4B";
                    case "gallop":
                      return "#F85C7F";
                    default:
                      return "silver";
                  }
                },
                borderWidth: 2,
                scaleID: "x",
                value: 0,
                adjustScaleRange: false,
              },
              zeroLine: this.generateZeroLine("y"),
              zeroLineY1: this.generateZeroLine("y1"),
              zeroLineY2: this.generateZeroLine("y2"),
              zeroLineY3: this.generateZeroLine("y3"),
              zeroLineY4: this.generateZeroLine("y4"),
              zeroLineY5: this.generateZeroLine("y5"),
              zeroLineY6: this.generateZeroLine("y6"),
              zeroLineY7: this.generateZeroLine("y7"),
            },
          },
          tooltip: {
            intersect: false,
            enabled: false,
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        height: 400,
        interaction: {
          mode: "index",
          axis: "x",
          intersect: false,
          display: false,
        },
        scales: {
          x: {
            type: "category",
            ticks: {
              color: (context) => {
                const second = this.getSecondsFromMinutesTimestamp(
                  context.tick.label
                );

                const currentGait =
                  this.secondsByGait.find((i) => i.second === second) ||
                  this.defaultGaitData;

                switch (currentGait.gait) {
                  case "walk":
                    return "#1AB0B0";
                  case "trot":
                    return "#FFCD4B";
                  case "gallop":
                    return "#F85C7F";
                  default:
                    return "gray";
                }
              },
            },
          },
          y: this.generateAxisOptions("y", "tracking.Speed"),
          y1: this.generateAxisOptions("y1", "tracking.Impulse"),
          y2: this.generateAxisOptions("y2", "tracking.Rhythm"),
          y3: this.generateAxisOptions("y3", "tracking.Stride length"),
          y4: this.generateAxisOptions("y4", "tracking.Acceleration"),
          y5: this.generateAxisOptions("y5", "tracking.Step time"),
          y6: this.generateAxisOptions("y6", "tracking.Rhythm deviation"),
          y7: this.generateAxisOptions("y7", "tracking.Heart rate"),
        },
      };
    },

    trackingModesOptions() {
      return this.GPSDatafiles ? this.trackingModes : [trackingModeKeys.ML];
    },

    trainingTime() {
      const tsArray = this.mappedData.map((i) => i.ts);
      const secondsArray = this.getSeconds(tsArray);

      const formattedTimes = secondsArray.map((seconds) => {
        // Round the seconds to the nearest whole number
        seconds = Math.round(seconds);

        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes.toString().padStart(2, "0")}:${remainingSeconds
          .toString()
          .padStart(2, "0")}`;
      });
      return [...new Set(formattedTimes)];
    },
    speed() {
      if (this.GPSDatafiles) {
        if (this.trackingMode === trackingModeKeys.GPS) {
          return this.generateSpeedByGPS();
        } else if (this.trackingMode === trackingModeKeys.ML) {
          return this.generateSpeedByML();
        }
        return [];
      } else if (Object.keys(this.reports.SUMMARY || {}).length) {
        return this.generateSpeedByML();
      }

      return [];
    },
    impulse() {
      if (!Object.keys(this.reports.IMPULSE || {}).length) return [];
      const impulseSecondsArray = this.getSeconds(
        this.reports.IMPULSE.timestamp
      );
      const impulseArray = this.reports.IMPULSE.impulse ?? [];
      return this.generateDataByTrainingTime(
        this.trainingTime,
        impulseSecondsArray,
        impulseArray,
        this.chartPoints
      );
    },
    rhythm() {
      if (!Object.keys(this.reports.RHYTHM || {}).length) return [];
      const rhythmSecondsArray = this.getSeconds(this.reports.RHYTHM.timestamp);
      const rhythmArray = this.reports.RHYTHM.rhythm ?? [];
      return this.generateDataByTrainingTime(
        this.trainingTime,
        rhythmSecondsArray,
        rhythmArray,
        this.chartPoints
      );
    },
    strideLength() {
      if (!Object.keys(this.reports.SUMMARY_RESAMPLED || {}).length) return [];
      const strideLengthSecondsArray = this.getSeconds(
        this.reports.SUMMARY_RESAMPLED.ts
      );
      const strideLengthArray =
        this.reports.SUMMARY_RESAMPLED.stride_length ?? [];
      return this.generateDataByTrainingTime(
        this.trainingTime,
        strideLengthSecondsArray,
        strideLengthArray,
        this.chartPoints
      );
    },
    acceleration() {
      if (!Object.keys(this.reports.SUMMARY_RESAMPLED || {}).length) return [];
      const accelerationSecondsArray = this.getSeconds(
        this.reports.SUMMARY_RESAMPLED.ts
      );
      const accelerationArray = this.reports.SUMMARY_RESAMPLED.avg_acc ?? [];
      return this.generateDataByTrainingTime(
        this.trainingTime,
        accelerationSecondsArray,
        accelerationArray,
        this.chartPoints
      );
    },
    stepTime() {
      if (!Object.keys(this.reports.SUMMARY || {}).length) return [];
      const durationSecondsArray = this.getSeconds(this.reports.SUMMARY.start);
      const stepTimeArray = this.reports.SUMMARY.duration ?? [];
      return this.generateDataByTrainingTime(
        this.trainingTime,
        durationSecondsArray,
        stepTimeArray,
        this.chartPoints
      );
    },
    rhythmDeviation() {
      if (!Object.keys(this.reports.RHYTHM || {}).length) return [];
      const rhythmSecondsArray = this.getSeconds(this.reports.RHYTHM.timestamp);
      const rhythmDeviationArray = this.reports.RHYTHM.rhythm_deviation ?? [];
      return this.generateDataByTrainingTime(
        this.trainingTime,
        rhythmSecondsArray,
        rhythmDeviationArray,
        this.chartPoints
      );
    },
    heartRate() {
      if (this.HRDatafiles) {
        return this.generateHeartRate();
      }
      return [];
    },
  },

  watch: {
    speed: {
      handler() {
        this.setDataProperties();
      },
    },
    async cutValue() {
      this.setDataProperties();
      this.movements.map((i) => (i.checked = true));
      await this.$nextTick();
      if (this.$refs.multiChart) {
        const chart = this.$refs.multiChart.chart;
        this.originalData = JSON.parse(JSON.stringify(chart.data.datasets));
      }
    },
    async isTrackStarted(newVal) {
      if (this.$refs.multiChart) {
        await this.$nextTick();
        const chart = this.$refs.multiChart.chart;
        if (newVal) {
          this.localZoom = {
            ...this.localZoom,
            x: {
              min: chart.options.scales.x.min,
              max: chart.options.scales.x.max,
              step:
                (chart.options.scales.x.max - chart.options.scales.x.min) / 2,
            },
          };
        } else {
          const currentTimelineIndex = this.currentTrackIndex
            ? Math.floor(this.currentTrackIndex / 5)
            : 0;
          chart.options.scales.x.min =
            currentTimelineIndex - this.localZoom.x.step;
          chart.options.scales.x.max =
            currentTimelineIndex + this.localZoom.x.step;
          this.localZoom = {
            ...this.localZoom,
            x: {
              min: chart.options.scales.x.min,
              max: chart.options.scales.x.max,
            },
          };
          chart.update();
        }
      }
    },
    currentTrackIndex(newVal) {
      this.changeAnnotation(newVal);
      this.setLegendData();
    },
    multicharts: {
      handler: function () {
        this.toggleChartInMultichart();
        this.setLegendData();
      },
      deep: true,
    },
    movements: {
      handler: async function () {
        if (this.isDataReady) {
          await this.toggleGaits();
          await this.$nextTick();
        }
      },
      deep: true,
    },
  },

  methods: {
    ...mapMutations([
      "SET_TRACKING_SPEED",
      "SET_CURRENT_TRACK_INDEX",
      "SET_IS_TRACK_STARTED",
      "SET_IS_TRACK_PAUSED",
      "SET_TRACKING_MODE",
    ]),
    ...mapActions(["handleTrackingMode"]),
    getClass,
    getSecond,
    getSeconds,
    getSecondsFromMinutesTimestamp,
    getBackgroundColorByGait,
    setLocalZoomData,
    setScalesMinMax,
    generateDataByTrainingTime,
    generateInterpolatedDataByTrainingTime,
    generateZeroLine,

    generateSpeedByGPS() {
      if (this.reports.GPS?.resampled) {
        const speedArray = this.reports.GPS.resampled.speed ?? [];
        const speedSecondsArray = this.getSeconds(
          this.reports.GPS.resampled.ts
        );
        return this.generateDataByTrainingTime(
          this.trainingTime,
          speedSecondsArray,
          speedArray,
          this.chartPoints
        );
      }
      return [];
    },
    generateSpeedByML() {
      if (this.reports.SUMMARY_RESAMPLED) {
        const avgSpeedSecondsArray = this.getSeconds(
          this.reports.SUMMARY_RESAMPLED.ts
        );
        const avgSpeedArray = this.reports.SUMMARY_RESAMPLED.avg_vel ?? [];
        return this.generateDataByTrainingTime(
          this.trainingTime,
          avgSpeedSecondsArray,
          avgSpeedArray,
          this.chartPoints
        );
      }
      return [];
    },
    generateHeartRate() {
      const heartRateArray = this.HRDatafiles.map((i) => i.hr) ?? [];
      const secondsArray = this.getSeconds(this.HRDatafiles.map((i) => i.ts));
      return this.generateInterpolatedDataByTrainingTime(
        this.trainingTime,
        secondsArray,
        heartRateArray,
        this.chartPoints
      );
    },

    extractValueInBrackets(text) {
      const match = text.match(/\(([^)]+)\)/);
      return match ? match[1] : "";
    },

    generateAxisOptions(id, label) {
      return {
        id: id,
        type: "linear",
        position: "left",
        ticks: {
          color: this.getMultichartColor(id),
          padding: 15,
        },
        border: {
          color: this.getMultichartColor(id),
        },
        grid: {
          color: this.getMultichartColor(id),
          drawOnChartArea: false,
        },
        title: {
          display: false,
          text: this.extractValueInBrackets(this.$t(label)),
          color: this.getMultichartColor(id),
          font: {
            weight: "bold",
          },
          padding: { top: 20, left: 0, right: 0, bottom: 0 },
        },
      };
    },
    handleCancel() {
      this.fullscreenMultichart = false;
      this.SET_IS_TRACK_STARTED(false);
      this.SET_IS_TRACK_PAUSED(true);
      this.toggleChartInMultichart();
    },
    handleClick() {
      this.fullscreenMultichart = true;
      this.SET_IS_TRACK_STARTED(false);
      this.SET_IS_TRACK_PAUSED(true);
      this.toggleChartInMultichart();
    },
    getMultichartColor(id) {
      return this.multicharts.filter((i) => i.scaleId === id)[0].color;
    },
    async setLegendData() {
      await this.$nextTick();
      const chart = this.$refs.multiChart;
      if (!chart) return [];

      const currentTimelineIndex = this.currentTrackIndex
        ? Math.floor(this.currentTrackIndex / 5)
        : 0;

      this.legendWithData = this.multicharts
        .filter((chart) => chart.checked)
        .map((multichart) => {
          const currentDataset = chart.chart.data.datasets.find(
            (dataset) => dataset.yAxisID === multichart.scaleId
          );

          if (!currentDataset || !Array.isArray(currentDataset.data)) {
            return {
              ...multichart,
              currentValue: {
                x: this.getMinutesTimestampFromSeconds(currentTimelineIndex),
                y: "-",
              },
            };
          }

          // Filter out any null or undefined values from currentDataset.data
          const validData = currentDataset.data.filter(
            (dataPoint) => dataPoint !== null && dataPoint !== undefined
          );

          // Find the last valid data point
          let lastValidData = validData.reduce((last, dataPoint) => {
            const dataTimeInSeconds = this.getSecondsFromMinutesTimestamp(
              dataPoint.x
            );
            if (dataTimeInSeconds <= currentTimelineIndex) {
              return dataPoint;
            }
            return last;
          }, null);

          return {
            ...multichart,
            currentValue: lastValidData?.y ?? "-",
          };
        });
    },
    async toggleChartInMultichart() {
      if (this.$refs.multiChart) {
        await this.$nextTick();
        const selectedChartIds = this.multicharts
          .filter((i) => i.checked)
          .map((i) => i.scaleId);
        const chart = this.$refs.multiChart.chart;

        chart.data.datasets.forEach((dataset, idx) => {
          if (selectedChartIds.includes(dataset.yAxisID)) {
            chart.data.datasets[idx].hidden = false;
            chart.options.scales[dataset.yAxisID].display = true;
          } else {
            chart.data.datasets[idx].hidden = true;
            chart.options.scales[dataset.yAxisID].display = false;
          }
        });

        this.setScalesMinMax(chart, this.localZoom);
        chart.update();
      }
    },
    async toggleGaits() {
      if (!this.$refs.multiChart) return;

      await this.$nextTick();

      const selectedGaitIds = new Set(
        this.movements
          .filter((movement) => movement.checked)
          .map((movement) => movement.id)
      );

      const chart = this.$refs.multiChart.chart;
      if (!chart) return;

      const datasets = chart.data.datasets;

      // Create a map for current gaits by second
      const gaitBySecondMap = new Map(
        this.secondsByGait.map((gait) => [gait.second, gait.gait])
      );
      // Create a map for fast lookup of original data by dataset index and timestamp
      const originalDataMap = this.originalData.map((dataSet) => {
        return new Map(dataSet.data.map((point) => [point.x, point.y]));
      });

      // Update data points
      datasets.forEach((dataset, idx) => {
        dataset.data.forEach((dataPoint) => {
          const secString = dataPoint.x;
          const currentSecond = this.getSecondsFromMinutesTimestamp(secString);
          const currentGait = gaitBySecondMap.get(currentSecond);
          const isVisible = selectedGaitIds.has(currentGait);

          if (!isVisible) {
            dataPoint.y = null;
          } else {
            const originalY = originalDataMap[idx].get(secString);
            dataPoint.y = originalY !== undefined ? originalY : 0;
          }
        });
      });

      await chart.update();
      await this.setLegendData();
    },

    async changeAnnotation(value) {
      if (this.$refs.multiChart) {
        await this.$nextTick();
        const chart = this.$refs.multiChart.chart;
        const currentSecond = value > 0 ? Math.floor(value / 5) : 0;

        // Calculate the visible range for the X-axis based on the current second
        let minX, maxX;
        if (this.isTrackStarted) {
          if (currentSecond < 90) {
            minX = 0;
            maxX = 180;
          } else {
            minX = currentSecond - 90;
            maxX = currentSecond + 90;
          }
          chart.options.scales.x.min = minX;
          chart.options.scales.x.max = maxX;
        } else {
          const step =
            (chart.options.scales.x.max - chart.options.scales.x.min) / 2;
          chart.options.scales.x.min = currentSecond - step;
          chart.options.scales.x.max = currentSecond + step;
          this.localZoom = {
            ...this.localZoom,
            x: {
              min: currentSecond - step,
              max: currentSecond + step,
              step: step,
            },
          };
        }
        chart.options.plugins.annotation.annotations.annotation.value =
          currentSecond;

        chart.update();
      }
    },

    setDataProperties() {
      this.chartData = {
        labels: this.trainingTime,
        datasets: [
          {
            label: this.$t("tracking.Speed"),
            data: this.speed,
            borderColor: this.getMultichartColor("y"),
            backgroundColor: this.getMultichartColor("y"),
            yAxisID: "y",
            display: false,
          },
          {
            type: "scatter",
            label: this.$t("tracking.Impulse"),
            data: this.impulse,
            borderColor: this.getMultichartColor("y1"),
            backgroundColor: this.getMultichartColor("y1"),
            yAxisID: "y1",
            display: false,
          },
          {
            elements: {
              point: {
                radius: 0,
              },
            },
            label: this.$t("tracking.Rhythm"),
            data: this.rhythm,
            borderColor: this.getMultichartColor("y2"),
            backgroundColor: this.getMultichartColor("y2"),
            yAxisID: "y2",
            display: false,
          },
          {
            type: "scatter",
            label: this.$t("tracking.Stride length"),
            data: this.strideLength,
            borderColor: this.getMultichartColor("y3"),
            backgroundColor: this.getMultichartColor("y3"),
            yAxisID: "y3",
            display: false,
          },
          {
            type: "scatter",
            label: this.$t("tracking.Acceleration"),
            data: this.acceleration,
            borderColor: this.getMultichartColor("y4"),
            backgroundColor: this.getMultichartColor("y4"),
            yAxisID: "y4",
            display: false,
          },
          {
            type: "scatter",
            label: this.$t("tracking.Step time"),
            data: this.stepTime,
            borderColor: this.getMultichartColor("y5"),
            backgroundColor: this.getMultichartColor("y5"),
            yAxisID: "y5",
            display: false,
          },
          {
            type: "scatter",
            label: this.$t("tracking.Rhythm deviation"),
            data: this.rhythmDeviation,
            borderColor: this.getMultichartColor("y6"),
            backgroundColor: this.getMultichartColor("y6"),
            yAxisID: "y6",
            display: false,
          },
          {
            elements: {
              point: {
                radius: 0,
              },
              line: {
                tension: 0.4,
              },
            },
            label: this.$t("tracking.Heart rate"),
            data: this.heartRate,
            borderColor: this.getMultichartColor("y7"),
            backgroundColor: this.getMultichartColor("y7"),
            yAxisID: "y7",
            display: false,
          },
        ],
      };
    },

    handleKeyDown(event) {
      const { key } = event;
      if (key === "Alt") {
        const chart = this.$refs.multiChart.chart;
        chart.options.plugins.tooltip.enabled = true;
        chart.update();
      }
    },
    handleKeyUp(event) {
      const { key } = event;
      if (key === "Alt") {
        const chart = this.$refs.multiChart.chart;
        chart.options.plugins.tooltip.enabled = false;
        chart.update();
      }
    },
  },

  async mounted() {
    if (this.$refs.multiChart) {
      this.isDataReady = false;
      this.movements.map((i) => (i.checked = true));
      this.setDataProperties();
      await this.setLegendData();

      await this.$nextTick();
      const chart = this.$refs.multiChart.chart;
      const minX = chart.options.scales.x.min ?? 0;
      const maxX = chart.options.scales.x.max ?? this.chartData.labels.length;

      this.localZoom.x = {
        min: minX,
        max: maxX,
      };

      this.originalData = JSON.parse(JSON.stringify(chart.data.datasets));
      await this.toggleChartInMultichart();
      document.addEventListener("keydown", this.handleKeyDown);
      document.addEventListener("keyup", this.handleKeyUp);
      this.isDataReady = true;
    }
  },

  beforeUnmount() {
    if (this.$refs.multiChart) {
      document.removeEventListener("keydown", this.handleKeyDown);
      document.removeEventListener("keyup", this.handleKeyUp);
    }
  },
};
</script>

<style lang="scss" scoped>
:deep(#trackingMode) {
  height: 40px;
  width: auto;
  font-size: 14px;
}
</style>
