<template>
  <div class="mx-auto w-[100%] lg:max-w-[42%] min-h-[350px] overflow-hidden">
    <div v-show="isTrackingDataExist">
      <div class="flex items-center">
        <div class="mb-2 mr-4">
          <input
            type="checkbox"
            class="w-4 h-4 focus:ring-0 focus:ring-offset-0 checked:text-[#5A5A5F] cursor-pointer"
            id="isTrackColored"
            v-model="isTrackColored"
          />
          <label
            for="isTrackColored"
            class="text-[14px] font-poppins text-[#5A5A5F] cursor-pointer ml-2"
          >
            <span> {{ $t("tracking.Show gaits") }} </span>
          </label>
        </div>
        <div class="mb-2 mr-2" v-show="trackingMode === trackingModeKeys.GPS">
          <input
            type="checkbox"
            class="w-4 h-4 focus:ring-0 focus:ring-offset-0 checked:text-[#5A5A5F] cursor-pointer"
            id="isMapVisible"
            v-model="isMapVisible"
          />
          <label
            for="isMapVisible"
            class="text-[14px] font-poppins text-[#5A5A5F] cursor-pointer ml-2"
          >
            <span> {{ $t("tracking.Show maps") }} </span>
          </label>
        </div>
      </div>

      <div class="relative">
        <div
          v-show="isMapVisible"
          class="controls absolute top-[10px] left-0 w-full h-400px z-10"
        >
          <button @click="setHeading(10)">
            <ArrowUturnLeftIcon
              class="h-6 w-6 text-center"
              aria-hidden="true"
            />
          </button>
          <button @click="setHeading(-10)">
            <ArrowUturnRightIcon
              class="h-6 w-6 text-center"
              aria-hidden="true"
            />
          </button>
          <button @click="zoomIn">
            <MagnifyingGlassPlusIcon
              class="h-6 w-6 text-center"
              aria-hidden="true"
            />
          </button>
          <button @click="zoomOut">
            <MagnifyingGlassMinusIcon
              class="h-6 w-6 text-center"
              aria-hidden="true"
            />
          </button>
          <button style="margin-left: auto" @click.prevent="handleClick">
            <ArrowsPointingOutIcon
              class="h-6 w-6 text-center"
              aria-hidden="true"
            />
          </button>
        </div>

        <div v-show="isMapVisible" id="map" class="rounded-lg"></div>
        <div
          id="tracking-chart"
          v-show="!isMapVisible"
          class="absolute top-0 left-0 bottom-0 right-0 z-1"
        ></div>
      </div>
    </div>

    <GoogleMapsModal
      v-if="fullscreenGoogleMaps"
      :is-open="fullscreenGoogleMaps"
      xMark
      large
      @handleCancel="handleCancel"
    >
      <template v-slot:title>
        <div class="flex items-center gap-3 mb-3">Google Maps Track</div>
      </template>
      <template v-slot:content>
        <div class="w-full flex flex-wrap gap-4 mb-4 h-[80%] relative">
          <div
            v-show="isMapVisible"
            class="controls absolute top-[10px] left-0 w-full h-400px z-10"
          >
            <button @click="setHeading(10)">
              <ArrowUturnLeftIcon
                class="h-6 w-6 text-center"
                aria-hidden="true"
              />
            </button>
            <button @click="setHeading(-10)">
              <ArrowUturnRightIcon
                class="h-6 w-6 text-center"
                aria-hidden="true"
              />
            </button>
            <button @click="zoomIn">
              <MagnifyingGlassPlusIcon
                class="h-6 w-6 text-center"
                aria-hidden="true"
              />
            </button>
            <button @click="zoomOut">
              <MagnifyingGlassMinusIcon
                class="h-6 w-6 text-center"
                aria-hidden="true"
              />
            </button>
          </div>
          <div id="fullscreenMap"></div>
        </div>

        <div class="mt-6 mb-4 mx-8 flex items-center gap-8">
          <div class="w-[30%]">
            <PlayControllerButtonsComponent
              :trackingMarkersLength="trackingMarkersLength"
              :cutValue="cutValue"
            />
            <div class="flex mt-1 gap-2">
              <div
                v-for="(item, index) of movements"
                :key="index"
                class="flex items-center mx-1"
              >
                <input
                  type="checkbox"
                  class="w-4 h-4 focus:ring-0 focus:ring-offset-0 checked:text-[#5A5A5F] cursor-pointer"
                  :id="item.title"
                  v-model="item.checked"
                />
                <span
                  class="w-[10px] h-[10px] mx-1 mb-[3px] rounded-full"
                  :class="getClass(item.backgroundColor)"
                ></span>
                <span class="text-[#5A5A5F] text-[18px]">
                  {{ $t(item.title) }}
                </span>
              </div>
            </div>
          </div>

          <div class="w-[70%]">
            <PlayControllerSliderComponent
              :trackingMarkersLength="trackingMarkersLength"
              :cutValue="cutValue"
            />
          </div>
        </div>
      </template>
    </GoogleMapsModal>

    <div v-show="!isTrackingDataExist">{{ $t("Data not found") }}</div>
  </div>
</template>

<script>
import Plotly from "plotly.js-dist";
import { mapGetters } from "vuex";
import { Loader } from "@googlemaps/js-api-loader";
import {
  ArrowUturnLeftIcon,
  ArrowUturnRightIcon,
  MagnifyingGlassPlusIcon,
  MagnifyingGlassMinusIcon,
} from "@heroicons/vue/20/solid";
import { ArrowsPointingOutIcon } from "@heroicons/vue/24/outline";
import GoogleMapsModal from "@/components/training/tracking/GoogleMapsModal.vue";
import PlayControllerSliderComponent from "@/components/training/tracking/PlayControllerSliderComponent.vue";
import PlayControllerButtonsComponent from "@/components/training/tracking/PlayControllerButtonsComponent.vue";
import { movements, trackingModeKeys } from "@/components/training/constants";
import { getClass } from "@/components/training/helpers";

const loader = new Loader({
  apiKey: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
  version: "beta",
  libraries: ["places"],
});

// global script value
let polylines = [];

export default {
  name: "HorseTrackingComponent",
  components: {
    PlayControllerButtonsComponent,
    PlayControllerSliderComponent,
    ArrowUturnLeftIcon,
    ArrowUturnRightIcon,
    MagnifyingGlassPlusIcon,
    MagnifyingGlassMinusIcon,
    ArrowsPointingOutIcon,
    GoogleMapsModal,
  },
  props: {
    tracking: Array,
    fromToCutValue: Array,
    lengthPreviousValues: Number,
    selectedGaits: Array,
    trackingMarkersLength: Number,
    cutValue: Array,
  },
  data() {
    return {
      movements,

      arr: [],
      mappedData: [],
      gaitTrackingMarkers: [],
      fromToCutMappedValue: [],
      trackingPoints: {},
      isTrackColored: true,
      isMapVisible: false,
      selectedGaitColors: [],
      marker: null,
      fullscreenGoogleMaps: false,
      currentPositionMarker: null,
      isTrackingMarkersLengthChanged: false,
      trackPlanCoordinates: [],
      mapOptions: {
        center: { lat: 0, lng: 0 },
        mapTypeId: "satellite",
        tilt: 0,
        heading: 90,
        zoomControl: false,
        mapTypeControl: false,
        scaleControl: true,
        streetViewControl: false,
        keyboardShortcuts: false,
        rotateControl: false,
        fullscreenControl: false,
        disableDefaultUI: true,
        zoom: 17,
      },
    };
  },
  watch: {
    async trackingMarkersLength() {
      this.isTrackingMarkersLengthChanged = true;
      await this.setTrackCoordinates();
      this.updateCurrentPositionMarker(this.currentTrackIndex);
    },
    async isTrackColored() {
      await this.setTrackCoordinates();
    },
    currentTrackIndex(newVal) {
      Plotly.react("tracking-chart", this.data, this.layout, {
        displayModeBar: false,
        scrollZoom: true,
      });

      this.updateCurrentPositionMarker(newVal);
    },

    async tracking() {
      if (this.trackingMode === trackingModeKeys.GPS) {
        // eslint-disable-next-line
        this.marker = new google.maps.Marker({
          position: {
            lat: this.mapOptions.center.lat,
            lng: this.mapOptions.center.lng,
          },
          map: this.map,
          title: "Start Position",
        });
      } else {
        this.isMapVisible = false;
      }

      this.setTrackingPoints();
      await Plotly.react("tracking-chart", this.data, this.layout, {
        displayModeBar: false,
        scrollZoom: true,
      });
      this.resetZoom();
    },

    fromToCutValue() {
      this.fromToCutMappedValue = this.fromToCutValue;
      Plotly.react("tracking-chart", this.data, this.layout, {
        displayModeBar: false,
        scrollZoom: true,
      });
    },

    historyArray() {
      Plotly.react("tracking-chart", this.data, this.layout, {
        displayModeBar: false,
        scrollZoom: true,
      });
    },

    selectedGaits: {
      handler: async function (newVal) {
        this.selectedGaitColors = newVal
          .filter((i) => i.checked)
          .map((i) => i.backgroundColor);
        Plotly.react("tracking-chart", this.data, this.layout, {
          displayModeBar: false,
          scrollZoom: true,
        });

        if (!this.isTrackingMarkersLengthChanged) {
          await this.setTrackCoordinates();
        }
        this.isTrackingMarkersLengthChanged = false;
      },
      deep: true,
    },
  },
  computed: {
    trackingModeKeys() {
      return trackingModeKeys;
    },
    ...mapGetters([
      "currentTrackIndex",
      "initialPositionGPS",
      "GPSDatafiles",
      "reports",
      "trackingMode",
    ]),
    isTrackingDataExist() {
      return this.tracking && this.tracking.length > 0;
    },
    layout() {
      return { ...this.getDefaultLayout() };
    },
    data() {
      if (!this.isTrackingDataExist) return null;
      const maxValue = this.tracking.length - 1;
      let marker = {
        x: [
          this.tracking[
            this.currentTrackIndex > this.tracking.length - 1
              ? maxValue
              : this.currentTrackIndex
          ].x,
        ],
        y: [
          this.tracking[
            this.currentTrackIndex > this.tracking.length - 1
              ? maxValue
              : this.currentTrackIndex
          ].y,
        ],
        mode: "markers",
        name: "Scatter",
        marker: {
          color: "red",
          size: 8,
        },
      };
      if (this.fromToCutMappedValue.length) {
        let sliceFromMarkerId = this.tracking.find(
          (i) => i.id === this.fromToCutMappedValue[0]
        );
        let sliceToMarkerId = this.tracking.find(
          (i) => i.id === this.fromToCutMappedValue[1]
        );

        let sliceFromMarkerIndex = this.tracking.findIndex(
          (i) => i.id === this.fromToCutMappedValue[0]
        );
        let sliceToMarkerIndex = this.tracking.findIndex(
          (i) => i.id === this.fromToCutMappedValue[1]
        );

        let SliceFromMarker = {
          x: [sliceFromMarkerId ? sliceFromMarkerId.x : this.tracking[0].x],
          y: [sliceFromMarkerId ? sliceFromMarkerId.y : this.tracking[0].y],
          mode: "markers",
          name: "Scatter",
          marker: {
            color: "grey",
            size: 4,
          },
        };
        let SliceToMarker = {
          x: [
            sliceToMarkerId
              ? sliceToMarkerId.x
              : this.tracking[this.tracking.length - 1].x,
          ],
          y: [
            sliceToMarkerId
              ? sliceToMarkerId.y
              : this.tracking[this.tracking.length - 1].y,
          ],
          mode: "markers",
          name: "Scatter",
          marker: {
            color: "grey",
            size: 4,
          },
        };

        let sliceFromTrack = {
          x: this.trackingPoints.x.slice(
            0,
            sliceFromMarkerIndex > -1 ? sliceFromMarkerIndex : 0
          ),
          y: this.trackingPoints.y.slice(
            0,
            sliceFromMarkerIndex > -1 ? sliceFromMarkerIndex : 0
          ),
          mode: "lines",
          line: {
            dash: "dash",
            color: "#d2d1d1",
            width: 0.5,
          },
        };
        let sliceToTrack = {
          x: this.trackingPoints.x.slice(
            sliceToMarkerIndex > -1 ? sliceToMarkerIndex : 0,
            sliceToMarkerIndex > -1 ? this.trackingPoints.x.length - 1 : 0
          ),
          y: this.trackingPoints.y.slice(
            sliceToMarkerIndex > -1 ? sliceToMarkerIndex : 0,
            sliceToMarkerIndex > -1 ? this.trackingPoints.y.length - 1 : 0
          ),
          mode: "lines",
          line: {
            dash: "dash",
            color: "#d2d1d1",
            width: 0.5,
          },
        };

        let track = {
          x: this.trackingPoints.x.slice(
            sliceFromMarkerIndex > -1 ? sliceFromMarkerIndex : 0,
            sliceToMarkerIndex > -1
              ? sliceToMarkerIndex
              : this.trackingPoints.x.length - 1
          ),
          y: this.trackingPoints.y.slice(
            sliceFromMarkerIndex > -1 ? sliceFromMarkerIndex : 0,
            sliceToMarkerIndex > -1
              ? sliceToMarkerIndex
              : this.trackingPoints.y.length - 1
          ),
          mode: "lines",
          line: {
            dash: "dash",
            color: "#5b5959",
            width: 0.5,
          },
        };
        return [
          ...this.previousValues,
          SliceFromMarker,
          SliceToMarker,
          marker,
          sliceFromTrack,
          sliceToTrack,
          track,
        ];
      } else {
        let track = {
          x: this.trackingPoints.x,
          y: this.trackingPoints.y,
          mode: "lines",
          line: {
            dash: "dash",
            color: "#5b5959",
            width: 0.5,
          },
        };
        return [...this.previousValues, track, marker];
      }
    },

    historyArray() {
      if (!this.isTrackColored) {
        const val =
          this.currentTrackIndex > this.lengthPreviousValues
            ? this.lengthPreviousValues
            : this.currentTrackIndex;
        return this.tracking.slice(
          this.currentTrackIndex - val,
          this.currentTrackIndex
        );
      }
      return this.tracking;
    },

    previousValues() {
      let type = [];
      let res = [];

      this.historyArray.map((i, index) => {
        if (
          this.historyArray[index + 1] &&
          i.color === this.historyArray[index + 1].color
        ) {
          type.push(i);
        } else if (
          !this.historyArray[index + 1] ||
          i.color !== this.historyArray[index + 1].color
        ) {
          type.push(i);
          if (this.selectedGaitColors.includes(type[0].color)) {
            res.push({
              mode: "lines",
              x: type.map((i) => i.x),
              y: type.map((i) => i.y),
              line: {
                color: this.selectedGaitColors.includes(type[0].color)
                  ? type[0].color
                  : "silver",
                width: 0.8,
              },
            });
          }

          type = [];
        }
      });
      return res;
    },
  },
  methods: {
    getClass,
    updateCurrentPositionMarker(newVal) {
      if (this.trackingMode === trackingModeKeys.GPS) {
        const position = this.trackPlanCoordinates[newVal];
        if (position && position.lat !== null && position.lng !== null) {
          if (this.currentPositionMarker) {
            this.currentPositionMarker.setPosition(position);
          } else {
            this.createCurrentPositionMarker(position);
          }
        } else if (this.currentPositionMarker) {
          this.currentPositionMarker.setMap(null);
        }
      }
    },
    async createCurrentPositionMarker(position) {
      // eslint-disable-next-line
      this.currentPositionMarker = new google.maps.Marker({
        position: position,
        map: this.map,
        icon: {
          // eslint-disable-next-line
          path: google.maps.SymbolPath.CIRCLE,
          scale: 2,
          fillColor: "red",
          fillOpacity: 1,
          strokeWeight: 5,
          strokeColor: "red",
        },
      });
    },
    initializeGoogleMaps(isFullscreen = false) {
      loader
        .load()
        .then(async (google) => {
          const mapElement = document.getElementById(
            isFullscreen ? "fullscreenMap" : "map"
          );
          mapElement.style.height = isFullscreen ? "100%" : "450px";
          mapElement.style.width = "100%";
          this.map = new google.maps.Map(mapElement, this.mapOptions);
          google.maps.event.addListener(this.map, "zoom_changed", () => {
            this.setHeading();
          });
          this.marker = new google.maps.Marker({
            position: {
              lat: this.mapOptions.center.lat,
              lng: this.mapOptions.center.lng,
            },
            map: this.map,
            title: "Start Position",
          });
          await this.setTrackCoordinates();

          this.currentPositionMarker = null;
          const position = this.trackPlanCoordinates[this.currentTrackIndex];
          await this.createCurrentPositionMarker(position);
        })
        .catch((e) => {
          console.error(e);
        });
    },
    handleCancel() {
      this.fullscreenGoogleMaps = false;
      this.mapOptions.zoom = 17;
      this.initializeGoogleMaps();
    },
    handleClick() {
      this.fullscreenGoogleMaps = true;
      this.mapOptions.zoom = 18;
      this.initializeGoogleMaps(true);
    },
    async setHeading(deg = 0) {
      await this.$nextTick();
      const newHeading = this.mapOptions.heading + deg;
      this.map.heading = newHeading;
      this.mapOptions.heading = newHeading;
      // eslint-disable-next-line
      google.maps.event.trigger(this.map, "center_changed");
    },

    zoomIn() {
      if (this.map) {
        this.map.setZoom(this.map.getZoom() + 1);
      }
    },
    zoomOut() {
      if (this.map) {
        this.map.setZoom(this.map.getZoom() - 1);
      }
    },
    getDefaultLayout() {
      return {
        dragmode: "pan",
        autosize: true,
        showlegend: false,
        hovermode: false,
        paper_bgcolor: "rgba(0,0,0,0)",
        plot_bgcolor: "rgba(0,0,0,0)",
        margin: {
          l: 50,
          r: 10,
          b: 40,
          t: 10,
        },
        xaxis: {
          title: {
            text: this.$t("tracking.X Axis in meters"),
            font: {
              family: "sans-serif, Manrope",
              size: 14,
              color: "lightgrey",
            },
          },
          showgrid: true,
          zeroline: false,
          showline: false,
          showticklabels: true,
          autorange: true,
          nticks: 10,
          domain: [0, 1],
        },
        yaxis: {
          title: {
            text: this.$t("tracking.Y Axis in meters"),
            font: {
              family: "sans-serif, Manrope",
              size: 14,
              color: "lightgrey",
            },
          },
          showgrid: true,
          zeroline: false,
          showline: false,
          showticklabels: true,
          autorange: true,
          scaleanchor: "x",
          nticks: 10,
          domain: [0, 1],
        },
      };
    },

    resetZoom() {
      const defaultLayout = this.getDefaultLayout();
      Plotly.relayout("tracking-chart", defaultLayout).then(() => {
        const plotDiv = document.getElementById("tracking-chart");
        const xAxisRange = plotDiv.layout.xaxis.range;
        const yAxisRange = plotDiv.layout.yaxis.range;

        // Calculate new ranges based on the desired zoom out level (0.85x)
        const zoomOutFactor = 0.15; // 15% zoom out
        const newXAxisRange = [
          xAxisRange[0] - ((xAxisRange[1] - xAxisRange[0]) * zoomOutFactor) / 2,
          xAxisRange[1] + ((xAxisRange[1] - xAxisRange[0]) * zoomOutFactor) / 2,
        ];
        const newYAxisRange = [
          yAxisRange[0] - ((yAxisRange[1] - yAxisRange[0]) * zoomOutFactor) / 2,
          yAxisRange[1] + ((yAxisRange[1] - yAxisRange[0]) * zoomOutFactor) / 2,
        ];

        Plotly.relayout("tracking-chart", {
          "xaxis.range": newXAxisRange,
          "yaxis.range": newYAxisRange,
        });
      });
    },

    setTrackingPoints() {
      this.trackingPoints.y = this.tracking.map((i) => i.y);
      this.trackingPoints.x = this.tracking.map((i) => i.x);
    },

    clearPolylines() {
      polylines.forEach((polyline) => {
        polyline.setMap(null);
        polyline = null;
      });
      polylines = [];
    },

    async setTrackCoordinates() {
      // Clear existing polylines only if necessary
      if (polylines.length > 0) {
        polylines.forEach((polyline) => polyline.setMap(null));
      }

      await this.$nextTick();

      const newPolylines = [];
      let segmentCoordinates = [];
      let currentColor = this.tracking[0] ? this.tracking[0].color : "#FF0000";
      const trackPlanCoordinates = [];

      this.tracking.forEach((point, index) => {
        if (point.lat !== null && point.lng !== null) {
          segmentCoordinates.push({ lat: point.lat, lng: point.lng });
          trackPlanCoordinates.push({ lat: point.lat, lng: point.lng });

          const isLastPoint = index === this.tracking.length - 1;
          const isColorChange =
            isLastPoint ||
            (this.tracking[index + 1] &&
              this.tracking[index + 1].color !== currentColor);

          if (isColorChange) {
            newPolylines.push(
              // eslint-disable-next-line
              new google.maps.Polyline({
                path: segmentCoordinates,
                geodesic: true,
                strokeColor:
                  this.isTrackColored &&
                  this.selectedGaitColors.includes(currentColor)
                    ? currentColor
                    : "#F5F5F5",
                strokeOpacity: 1.0,
                strokeWeight: 2,
              })
            );
            segmentCoordinates = [];
            if (!isLastPoint) currentColor = this.tracking[index + 1].color;
          }
        } else {
          if (segmentCoordinates.length > 0) {
            newPolylines.push(
              // eslint-disable-next-line
              new google.maps.Polyline({
                path: segmentCoordinates,
                geodesic: true,
                strokeColor: currentColor,
                strokeOpacity: 1.0,
                strokeWeight: 2,
              })
            );
            segmentCoordinates = [];
          }
          trackPlanCoordinates.push(null);
        }
      });

      // Batch set polylines on the map
      newPolylines.forEach((polyline) => polyline.setMap(this.map));
      polylines = newPolylines;

      this.trackPlanCoordinates = trackPlanCoordinates;
      this.isTrackingMarkersLengthChanged = false;
    },
  },

  mounted() {
    if (this.isTrackingDataExist) {
      this.selectedGaitColors = this.selectedGaits
        .filter((i) => i.checked)
        .map((i) => i.backgroundColor);

      this.mapOptions.center = {
        lat: this.initialPositionGPS.latitude,
        lng: this.initialPositionGPS.longitude,
      };

      this.setTrackingPoints();
      Plotly.newPlot("tracking-chart", this.data, this.layout, {
        displayModeBar: false,
        scrollZoom: true,
      });
      this.resetZoom();
      this.initializeGoogleMaps();
    }
  },
};
</script>

<style lang="scss" scoped>
.controls {
  display: flex;

  button {
    padding: 5px 10px;
    margin: 0 5px;
    background: #fff;
    color: #666;
  }
}
</style>
